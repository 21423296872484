import axiosInstance from "./index";

interface Company {
  id: string;
  name: string;
  director?: string;
  tin?: string;
  driversCount?: number;
  transportsCount?: number;
  createdYear?: number;
}

export interface Location {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  type?: string;
  company: Company;
  entityType: "transport" | "trailer" | "user" | "load-pickup" | "load-delivery";
  updatedAt: string;
  city?: string;
  address?: string;
}

export interface CompanyLocations {
  transports: Location[];
  trailers: Location[];
  employees: Location[];
  loads: Location[];
}

export const locationsApi = {
  getCompanyLocations: async (companyId: string) => {
    console.log("API: Fetching locations for company:", companyId);
    const response = await axiosInstance.get<CompanyLocations>(
      `/locations/company/${companyId}`,
    );
    console.log("API: Response data:", response.data);
    return response;
  },

  updatePosition: (
    type: string,
    id: string,
    data: { latitude: number; longitude: number },
  ) => {
    console.log("API: Updating position for", type, id, "with data:", data);
    return axiosInstance.post(`/locations/${type}/${id}/position`, data);
  },

  getPosition: (type: string, id: string) => {
    console.log("API: Getting position for", type, id);
    return axiosInstance.get<Location>(`/locations/${type}/${id}`);
  },
};
