import { ChangeEvent } from 'react';
import Input from '../../../../../components/common/Input'
import DragNDrop from '../../../../../components/common/DragNDrop'
import './styles.sass'
import Button from "../../../../../components/common/Button";
import Combobox from "../../../../../components/common/Combobox";

const LogisticianTab = () => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if(fileList?.length)
      console.log(fileList[0]);
  }

  return (
      <div className='logistician-tab'>
          <Input label='Litsenziya raqami' placeholder='Litsenziya raqamini kiriting'/>
          {/*<DragNDrop label='Litsenziya fayli' onChange={onChangeHandler}/>*/}
          
          <br/>
          <Combobox
              label='Biz haqimizda qayerdan eshitdingiz?'
              options={[
                  {label: 'Do\'stim taklif qildi', value: 0},
                  {label: 'Ijtimoiy tarmoqlardan', value: 1},
                  {label: 'Websayt sahifalaridan', value: 2}
              ]}
              onSelect={(value) => {
                  console.log(value)
              }}
          />

          <Button type='submit' fullWidth size='lg' disabled style={{marginTop: 24}}>
              Yakunlash
          </Button>
      </div>
  )
}

export default LogisticianTab