import { useRef, useState } from 'react';
import { TrashIcon, ArrowIcon, FolderIcon, ExternalLink, ImgIcon } from "../../../utils/icons";
import { ModalProps } from '../../../types/modal.types';
import { CompanyDocument } from '../../../api/types/company/CompanyDocument';
import Button from '../Button';
import './styles.sass';
import classNames from 'classnames';

interface AccordionProps {
  documents: CompanyDocument[];
  onRemoveModal: ModalProps;
  onDocumentClick?: (document: CompanyDocument) => void;
}

const Accordion = ({ onRemoveModal, documents, onDocumentClick }: AccordionProps) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const { openModal } = onRemoveModal;

  // Group documents by type
  console.log(documents)
  const groupedDocuments = documents.reduce<Record<string, CompanyDocument[]>>((acc, doc) => {
    const type = doc.entityType || 'OTHER';
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(doc);
    return acc;
  }, {});

  const onItemClick = (type: string, event: React.MouseEvent) => {
    if ((event.target as HTMLElement).closest('button')) {
      return;
    }
    setActiveItem(activeItem === type ? null : type);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className='accardion'>
      {Object.entries(groupedDocuments).map(([type, docs]) => (
        <div
          className={classNames('accardion__item', { 'accardion__item--active': type === activeItem })}
          key={type}
        >
          <div className='accardion__header' onClick={(event) => onItemClick(type, event)}>
            <div className='header-wrapper'>
              <div className='icon-wrapper'>
                <ArrowIcon className={classNames('accardion__arrow', { 'accardion__arrow--active': type === activeItem })} />
                <FolderIcon />
              </div>

              <div className='header-title'>{type}</div>

              <div className='header-date'>{docs.length} documents</div>
            </div>
          </div>
          <div
            className='accardion__collapse'
            style={{ height: type === activeItem ? 'auto' : 0 }}
          >
            {docs.map((doc, idx) => (
              <div
                className='accardion__body'
                key={doc.id}
                ref={el => refs.current[idx] = el}
                onClick={() => onDocumentClick?.(doc)}
              >
                <div className='accardion__body-wrapper'>
                  <ImgIcon />

                  <span className='file-name'>
                    {doc.name}
                  </span>

                  {/* <span className='file-date'>
                    {formatDate(doc.uploadDate)}
                  </span> */}

                  <ExternalLink />
                </div>

                <Button variant='clear' onClick={(event) => {
                  event.stopPropagation();
                  openModal();
                }}>
                  <TrashIcon className='body__remove-btn' />
                </Button>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
