import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../..';
import { Load } from '../../types/cargo.types';

// Function to fetch cargo list
const fetchCargoList = async (): Promise<Load[]> => {
    const response = await axiosInstance.get('/loads');
    return response.data;
};

// Hook for getting cargo list
const useCargoList = () => {
    return useQuery<Load[], Error>({
        queryKey: ['cargo-list'],
        queryFn: fetchCargoList,
    });
};

export default useCargoList;
