import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/common/Button';
import PageInfo from '../../../components/dashboard/PageInfo';
import ContentWrapper from '../../../components/common/ContentWrapper';
import FlexWrapper from '../../../components/common/FlexWrapper';
import Input from '../../../components/common/Input';
import Combobox from '../../../components/common/Combobox';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {useEffect, useState} from 'react';
import { useFetchEmployee } from "../../../api/hooks/company/employe/useFetchEmployee";
import { useUpdateUser } from "../../../api/hooks/company/employe/useUpdateUser";
import {toast} from "react-toastify";
import queryClient from "../../../config/react-query/queryClient"; // Импортируйте хук для обновления

const DriversEdit = () => {
    const navigate = useNavigate();
    const { userID } = useParams();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const {companyID} = useParams();
    const [password, setPassword] = useState('');
    const { data, isLoading } = useFetchEmployee(userID); // Получаем данные пользователя
    const { mutate: updateUser, isError, error } = useUpdateUser(userID); // Хук для обновления данных

    useEffect(() => {
        if (data && data[0].user) {
            const driverData = data[0].user;
            console.log("Fetched data: ", driverData);

            // Устанавливаем значения в форму
            setValue('name', driverData.name);
            setValue('surename', driverData.surename);
            setValue('phone', driverData.phone);
            setValue('serial', driverData.serial);
            setValue('serialNumber', driverData.serialNumber);
            setValue('pinfl', driverData.pinfl);
            setValue('driverLicenseSerial', driverData.driverLicenseSerial);
            setValue('driverLicenseNumber', driverData.driverLicenseNumber);
            setValue('driverCategory', driverData.driverCategory);
            setValue('logistLicense', driverData.logistLicense);
            setValue('region', driverData.region);
            setValue('city', driverData.city);
            setValue('street', driverData.street);
            setValue('zipCode', driverData.zipCode);
        }
    }, [data, setValue]);

    // Если данные загружаются, показываем индикатор загрузки
    if (isLoading) {
        return <div className='page-drivers-create'>Loading...</div>;
    }
    if (isError) {
        console.log("Error:", error);
    }
    const onSubmit = (formData: any) => {
        const updatedData = { ...formData };

        // Отправляем пароль только если он изменен и соответствует требованиям
        if (password.length >= 6) {
            updatedData.password = password;
        } else if (password.length > 0) {
            toast.error(t('password_min_length'));
            return; // Прерываем отправку, если пароль слишком короткий
        }

        updateUser(updatedData, {
            onSuccess: () => {
                const queryKey: any = ['companyDrivers', companyID];
                queryClient.invalidateQueries(queryKey);
                toast.success(t('driver_updated_successfully'));
                navigate(-1);
            },
            onError: () => {
                toast.error(t('driver_update_failed'));
            },
        });
    };

    return (
        <>
            <div className='page-drivers-create'>
                <PageInfo
                    title={t('edit_driver')}
                    backButton
                    buttonsSlot={
                        <Button onClick={handleSubmit(onSubmit)}>
                            {t('save')}
                        </Button>
                    }
                />

                <ContentWrapper rowGap={16}>
                    <h3 className="section-title">{t('driver_info')}</h3>

                    <FlexWrapper>
                        <Input
                            label={t('driver_name')}
                            placeholder={t('enter_name')}
                            containerStyle={{ flex: 3 }}
                            {...register('name', { required: true })}
                            error={errors.name && t('field_required')}
                        />
                        <Input
                            label={t('surname')}
                            placeholder={t('enter_surname')}
                            containerStyle={{ flex: 3 }}
                            {...register('surename', { required: true })}
                            error={errors.surname && t('field_required')}
                        />
                        <Input
                            label="PINFL"
                            placeholder="Enter PINFL"
                            {...register('pinfl', { required: true })}
                            error={errors.pinfl && t('field_required')}
                        />
                    </FlexWrapper>

                    <FlexWrapper>
                        <Input
                            label={t('passport_series')}
                            placeholder={t('enter_passport_series')}
                            containerStyle={{ flex: 1 }}
                            {...register('serial', { required: true })}
                            error={errors.serial && t('field_required')}
                        />
                        <Input
                            label={t('passport_number')}
                            placeholder={t('enter_passport_number')}
                            containerStyle={{ flex: 2 }}
                            {...register('serialNumber', { required: true })}
                            error={errors.serialNumber && t('field_required')}
                        />
                    </FlexWrapper>

                    <FlexWrapper>
                        <Input
                            label={t('license_series')}
                            placeholder={t('enter_license_series')}
                            {...register('driverLicenseSerial', { required: true })}
                            error={errors.driverLicenseSerial && t('field_required')}
                        />
                        <Input
                            label={t('license_number')}
                            placeholder={t('enter_license_number')}
                            {...register('driverLicenseNumber', { required: true })}
                            error={errors.driverLicenseNumber && t('field_required')}
                        />
                        <Input
                            label="Logist License"
                            placeholder="Enter logist license"
                            {...register('logistLicense', { required: true })}
                            error={errors.logistLicense && t('field_required')}
                        />
                        <Combobox 
                            label={t('license_category')} 
                            placeholder='B'
                            options={[
                                { label: 'B', value: 'B' },
                                { label: 'BC', value: 'BC' },
                                { label: 'C', value: 'C' }
                            ]}
                            onSelect={(value: string | number) => setValue('driverCategory', value.toString())}
                        />
                    </FlexWrapper>

                    <h3 className="section-title">{t('address')}</h3>
                    <ContentWrapper background='ltblue'>
                        <FlexWrapper>
                            <Input
                                label={t('region')}
                                placeholder={t('enter_region')}
                                {...register('region', { required: true })}
                                error={errors.region && t('field_required')}
                            />
                            <Input
                                label={t('city')}
                                placeholder={t('enter_city')}
                                {...register('city', { required: true })}
                                error={errors.city && t('field_required')}
                            />
                            <Input
                                label={t('street_name')}
                                placeholder={t('enter_street_name')}
                                {...register('street', { required: true })}
                                error={errors.street && t('field_required')}
                            />
                            <Input
                                label={t('zip_code')}
                                placeholder={t('zip_code')}
                                {...register('zipCode', { required: true })}
                                error={errors.zipCode && t('field_required')}
                            />
                        </FlexWrapper>
                    </ContentWrapper>

                </ContentWrapper>
            </div>
            <div className='page-drivers-create'>
                <ContentWrapper>
                    <h2 className="section-title">Akkauntga kirish</h2>
                    <FlexWrapper alignItems={"flex-end"}>
                        <Input
                            label="Telefon raqam"
                            placeholder="+998 (99) 111-22-33"
                            containerStyle={{ flex: 3 }}
                            {...register('phone', { required: true })}
                            error={errors.phone && t('field_required')}
                        />
                        <Input
                            label="Parol"
                            placeholder="******"
                            type="password"
                            minLength={8}

                            containerStyle={{ flex: 3 }}
                            onChange={e => setPassword( e.target.value)}
                        />
                    </FlexWrapper>
                </ContentWrapper>
            </div>
        </>
    );
};

export default DriversEdit;