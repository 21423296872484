import { useNavigate } from 'react-router-dom';
import Logo from '../../common/Logo';
import UserAvatar from '../../../assets/temporary/user-avatar.png';
import {
	DashboardIcon,
	BoxIcon,
	BuildingIcon,
	TruckIcon,
	MapIcon,
	DocIcon,
	SettingsIcon,
	SideBarIcon
} from "../../../utils/icons";
import useModal from '../../../hooks/useModal';
import { Modal } from '../../common/Modal';
import { useTranslation } from 'react-i18next';
import './styles.sass';
import Cookies from "js-cookie";
import { getUserIdFromToken } from "../../../utils/authUtils";
import useMe from "../../../api/hooks/useMe";
import { useState } from 'react';
import FlexWrapper from "../../common/FlexWrapper";

interface SidebarProps {
	isCollapsed: boolean;
	onToggle: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, onToggle }) => {
	const navigate = useNavigate();
	const { openModal, isOpen, closeModal } = useModal();
	const { t } = useTranslation();
	const userId = getUserIdFromToken();

	const { data: me, isLoading } = useMe(userId);

	// Состояние для модального окна подтверждения
	const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

	// Функция выхода с подтверждением
	const logout = () => {
		Cookies.remove('accessToken');
		navigate('/auth/sign-in');
	};

	// Открытие модального окна подтверждения
	const confirmLogout = () => {
		setShowLogoutConfirm(true);
	};

	// Закрытие модального окна подтверждения
	const cancelLogout = () => {
		setShowLogoutConfirm(false);
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
			<div className='sidebar-header'>
				<Logo type='full' viewStyle='white' width={isCollapsed ? '80px' : '150px'} height='100px' />
			</div>
			<button className='toggle-button' onClick={onToggle}>
				<SideBarIcon />
			</button>
			<div className='sidebar-navigation'>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><DashboardIcon /></span>
					{!isCollapsed && <div className="navigation__text">{t('dashboard')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={() => navigate('companies')}>
					<span className="navigation__icon"><BuildingIcon /></span>
					{!isCollapsed && <div className="navigation__text">{t('companies')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={() => navigate('cargo')}>
					<span className="navigation__icon"><BoxIcon /></span>
					{!isCollapsed && <div className="navigation__text">{t('cargoTitle')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={() => navigate('maps')}>
					<span className="navigation__icon"><MapIcon /></span>
					{!isCollapsed && <div className="navigation__text">{t('maps')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><DocIcon /></span>
					{!isCollapsed && <div className="navigation__text">{t('documents')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><SettingsIcon /></span>
					{!isCollapsed && <div className="navigation__text">{t('settings')}</div>}
				</div>
			</div>

			<div className='sidebar-bottom'>
				{!isCollapsed && (
					<div className='user-information'>
						{/*<div className='user-avatar'>*/}
						{/*	<img src={UserAvatar} alt='User avatar' />*/}
						{/*</div>*/}
						<div className='user-contact'>
							<div className='user-contact-fullname'>
								<span>{me?.name} {me?.surename}</span>
							</div>
							<div className='user-contact-phone'>
								<span>{me?.phone}</span>
							</div>
							<div
								style={{ color: "red", fontSize: "12px" }}
								onClick={confirmLogout}
							>
								{t('logout')}
							</div>
						</div>
					</div>
				)}
			</div>

			{showLogoutConfirm && (
				<Modal title={t('logoutConfirmationTitle')} onClose={cancelLogout}>
					<div className="modal-body">
						<p>{t('logoutConfirmationMessage')}</p>
						<FlexWrapper>
							<button className={'button button--primary button--md button--full-width'} onClick={logout}>{t('yes')}</button>
							<button className={"button button--secondary button--md button--full-width"} onClick={cancelLogout}>{t('no')}</button>
						</FlexWrapper>
					</div>
				</Modal>
			)}

			{isOpen && (
				<Modal title={t('info')} titlePosition='center' onClose={closeModal}>
					<div className="modal-routes-info">{t('nav-msg')}</div>
				</Modal>
			)}
		</div>
	);
};

export default Sidebar;