import { Placemark } from "@pbe/react-yandex-maps";
import YandexMap from "../../../components/dashboard/Map";
import { ReactNode, useEffect, useState } from "react";
import FlexWrapper from "../../../components/common/FlexWrapper";
import PageInfo from "../../../components/dashboard/PageInfo";
import { CompanyLocations, locationsApi } from "../../../api/locations";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { getUserIdFromToken } from "../../../utils/authUtils";
import useCompanies from "../../../api/hooks/company/useCompanies";
import truckIcon from "../../../assets/global/map-icons/truck.svg";
import cargoIcon from "../../../assets/global/map-icons/cargo.svg";
import driverIcon from "../../../assets/global/map-icons/driver.svg";
interface Props {
  navigationSlot: ReactNode;
}

function AllItems({ navigationSlot }: Props) {
  const [locations, setLocations] = useState<CompanyLocations | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const userId = getUserIdFromToken();
  const { data: companies, isLoading: isLoadingCompanies } =
    useCompanies(userId);

  // Автоматически выбираем компанию если она одна
  useEffect(() => {
    if (companies?.length === 1 && !selectedCompany) {
      setSelectedCompany(companies[0].id);
    }
  }, [companies, selectedCompany]);

  useEffect(() => {
    if (!selectedCompany) {
      setLocations(null);
      setError(null);
      return;
    }

    setLoading(true);
    setError(null);

    locationsApi
      .getCompanyLocations(selectedCompany)
      .then((response: AxiosResponse<CompanyLocations>) => {
        console.log("Received locations data:", response.data);
        setLocations(response.data);
      })
      .catch((error: any) => {
        console.error("Failed to fetch locations:", error);
        setError(t("Failed to fetch locations"));
        setLocations(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCompany, t]);

  const handleCompanyChange = (value: string) => {
    console.log("Selected company ID:", value);
    setSelectedCompany(value || null);
  };

  const renderContent = () => {
    if (isLoadingCompanies) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("Loading companies...")}
        </div>
      );
    }

    if (!companies?.length) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("No companies available")}
        </div>
      );
    }

    if (!selectedCompany) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("Please select a company")}
        </div>
      );
    }

    if (loading) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("Loading locations...")}
        </div>
      );
    }

    if (error) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
          }}
        >
          {error}
        </div>
      );
    }

    return (
      <div style={{ height: "calc(100vh - 250px)" }}>
        <YandexMap>
          {/* Transport markers */}
          {locations?.transports?.map((transport) => {
            const lat = parseFloat(transport.latitude as any);
            const lng = parseFloat(transport.longitude as any);
            if (isNaN(lat) || isNaN(lng)) return null;

            // Форматируем дату последнего обновления
            const lastUpdate = new Date(transport.updatedAt).toLocaleString('ru-RU', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            });

            // Форматируем координаты с округлением до 6 знаков
            const formattedLat = Number(lat).toFixed(6);
            const formattedLng = Number(lng).toFixed(6);

            return (
              <Placemark
                key={transport.id}
                geometry={[lat, lng]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: truckIcon,
                  iconImageSize: [60, 62],
                  iconImageOffset: [-30, -62],
                  hideIconOnBalloonOpen: false,
                  balloonCloseButton: true,
                }}
                properties={{
                  hintContent: transport?.name || t("Transport"),
                  balloonContentHeader: `<strong>${transport?.name || t("Transport")}</strong>`,
                  balloonContentBody: `
                    <div style="font-family: Arial, sans-serif;">
                      <div style="margin-bottom: 8px;">
                        <strong>${t("ID")}:</strong> ${transport?.id}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Company")}:</strong> ${transport?.company?.name || t("Not specified")}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Type")}:</strong> ${transport?.type || t("Not specified")}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Coordinates")}:</strong><br/>
                        ${t("Latitude")}: ${formattedLat}<br/>
                        ${t("Longitude")}: ${formattedLng}
                      </div>
                      <div>
                        <strong>${t("Last Update")}:</strong><br/>
                        ${lastUpdate}
                      </div>
                    </div>
                  `,
                  // balloonContentFooter: `
                  //   <div style="text-align: center; margin-top: 10px;">
                  //     <a href="/dashboard/transport/${transport?.id}" target="_blank" style="color: #2196f3;">
                  //       ${t("View Details")}
                  //     </a>
                  //   </div>
                  // `,
                }}
                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              />
            );
          })}

          {/* Trailer markers */}
          {locations?.trailers?.map((trailer) => {
            const lat = parseFloat(trailer.latitude as any);
            const lng = parseFloat(trailer.longitude as any);
            if (isNaN(lat) || isNaN(lng)) return null;

            // Форматируем дату последнего обновления
            const lastUpdate = new Date(trailer.updatedAt).toLocaleString('ru-RU', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            });

            // Форматируем координаты с округлением до 6 знаков
            const formattedLat = Number(lat).toFixed(6);
            const formattedLng = Number(lng).toFixed(6);

            return (
              <Placemark
                key={trailer.id}
                geometry={[lat, lng]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: cargoIcon,
                  iconImageSize: [60, 62],
                  iconImageOffset: [-30, -62],
                  hideIconOnBalloonOpen: false,
                  balloonCloseButton: true,
                }}
                properties={{
                  hintContent: trailer?.name || t("Trailer"),
                  balloonContentHeader: `<strong>${trailer?.name || t("Trailer")}</strong>`,
                  balloonContentBody: `
                    <div style="font-family: Arial, sans-serif;">
                      <div style="margin-bottom: 8px;">
                        <strong>${t("ID")}:</strong> ${trailer?.id}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Company")}:</strong> ${trailer?.company?.name || t("Not specified")}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Type")}:</strong> ${trailer?.type || t("Not specified")}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Coordinates")}:</strong><br/>
                        ${t("Latitude")}: ${formattedLat}<br/>
                        ${t("Longitude")}: ${formattedLng}
                      </div>
                      <div>
                        <strong>${t("Last Update")}:</strong><br/>
                        ${lastUpdate}
                      </div>
                    </div>
                  `,
                  // balloonContentFooter: `
                  //   <div style="text-align: center; margin-top: 10px;">
                  //     <a href="/dashboard/trailer/${trailer?.id}" target="_blank" style="color: #2196f3;">
                  //       ${t("View Details")}
                  //     </a>
                  //   </div>
                  // `,
                }}
                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              />
            );
          })}

          {/* Employee markers */}
          {locations?.employees?.map((employee) => {
            const lat = parseFloat(employee.latitude as any);
            const lng = parseFloat(employee.longitude as any);
            if (isNaN(lat) || isNaN(lng)) return null;

            // Форматируем дату последнего обновления
            const lastUpdate = new Date(employee.updatedAt).toLocaleString('ru-RU', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            });

            // Форматируем координаты с округлением до 6 знаков
            const formattedLat = Number(lat).toFixed(6);
            const formattedLng = Number(lng).toFixed(6);

            return (
              <Placemark
                key={employee.id}
                geometry={[lat, lng]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: driverIcon,
                  iconImageSize: [60, 62],
                  iconImageOffset: [-30, -62],
                  hideIconOnBalloonOpen: false,
                  balloonCloseButton: true,
                }}
                properties={{
                  hintContent: employee?.name || t("Employee"),
                  balloonContentHeader: `<strong>${employee?.name || t("Employee")}</strong>`,
                  balloonContentBody: `
                    <div style="font-family: Arial, sans-serif;">
                      <div style="margin-bottom: 8px;">
                        <strong>${t("ID")}:</strong> ${employee?.id}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Company")}:</strong> ${employee?.company?.name || t("Not specified")}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Type")}:</strong> ${employee?.type || t("Not specified")}
                      </div>
                      <div style="margin-bottom: 8px;">
                        <strong>${t("Coordinates")}:</strong><br/>
                        ${t("Latitude")}: ${formattedLat}<br/>
                        ${t("Longitude")}: ${formattedLng}
                      </div>
                      <div>
                        <strong>${t("Last Update")}:</strong><br/>
                        ${lastUpdate}
                      </div>
                    </div>
                  `,
                  // balloonContentFooter: `
                  //   <div style="text-align: center; margin-top: 10px;">
                  //     <a href="/dashboard/employee/${employee?.id}" target="_blank" style="color: #2196f3;">
                  //       ${t("View Details")}
                  //     </a>
                  //   </div>
                  // `,
                }}
                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              />
            );
          })}

          {/* Load points */}
          {locations?.loads?.map((point) => (
            <Placemark
              key={point.id}
              geometry={[point?.latitude, point.longitude]}
              options={{
                iconLayout: "default#image",
                iconImageHref: cargoIcon,
                iconImageSize: [60, 62],
                iconImageOffset: [-30, -62],
                hideIconOnBalloonOpen: false,
                balloonCloseButton: true,
              }}
              properties={{
                hintContent: point.name,
                balloonContentHeader: `<strong>${t(point.entityType === 'load-pickup' ? 'load_pickup' : 'load_delivery')}</strong>`,
                balloonContentBody: `
                  <div style="font-family: Arial, sans-serif;">
                    <div style="margin-bottom: 8px;">
                      <strong>${t("load_id")}:</strong> ${point.id.split('-')[0]}
                    </div>
                    <div style="margin-bottom: 8px;">
                      <strong>${t("load_name")}:</strong> ${point.name}
                    </div>
                    <div style="margin-bottom: 8px;">
                      <strong>${t("load_city")}:</strong> ${point.city || t("Not specified")}
                    </div>
                    <div style="margin-bottom: 8px;">
                      <strong>${t("load_address")}:</strong> ${point.address || t("Not specified")}
                    </div>
                    <div style="margin-bottom: 8px;">
                      <strong>${t("Coordinates")}:</strong><br/>
                      ${t("Latitude")}: ${Number(point.latitude).toFixed(6)}<br/>
                      ${t("Longitude")}: ${Number(point.longitude).toFixed(6)}
                    </div>
                    <div>
                      <strong>${t("Last Update")}:</strong><br/>
                      ${new Date(point.updatedAt).toLocaleString()}
                    </div>
                  </div>
                `,
              }}
              modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
            />
          ))}
        </YandexMap>
      </div>
    );
  };

  return (
    <div className="page-maps-view">
      <PageInfo backButton title={t("Xaritalar")} url="/dashboard" />
      <FlexWrapper>
        {navigationSlot}
        <div className="page-content">
          <div style={{ marginBottom: "20px" }}>
            {/* Показываем селект только если компаний больше одной */}
            {companies && companies.length > 1 && (
              <select
                style={{
                  width: "300px",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #d9d9d9",
                }}
                value={selectedCompany || ""}
                onChange={(e) => handleCompanyChange(e.target.value)}
                disabled={isLoadingCompanies}
              >
                <option value="">{t("Select a company")}</option>
                {Array.isArray(companies) &&
                  companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          {renderContent()}
        </div>
      </FlexWrapper>
    </div>
  );
}

export default AllItems;
