import { useState } from 'react'
import RoleCard from '../../../components/authorization/RoleCard'
import FlexWrapper from '../../../components/common/FlexWrapper'
import ProgressBar from '../../../components/common/ProgressBar'
import Button from '../../../components/common/Button'
import LogisticianTab from './RolesTabs/LogisticianTab'
import TruckDeliveryTab from './RolesTabs/TruckDeliveryTab'
import CargoOwnerTab from './RolesTabs/CargoOwnerTab'
import { RoleCards } from '../../../utils/consts'
import { ROLES } from '../../../types/roles.types'
import './styles.sass'
import {useUpdateUser} from "../../../api/hooks/ useUpdateUser";
import {Modal} from "../../../components/common/Modal";
import {useTranslation} from "react-i18next";

const Addtional = () => {
    const [selectedRole, setSelectedRole] = useState<ROLES | null>(null);
    const [selectedAmount, setSelectedAmount] = useState('');
    const [currentStep, setCurrentStep] = useState(1); // Начальный шаг
    const [modal, setModal] = useState<boolean>(false);
    const { mutate: userUpdate } = useUpdateUser(); // Initialize the update user mutation
    const {t} = useTranslation();

    const handleRoleClick = (role: ROLES) => {

        if (role === ROLES.LOGISTICIAN) {
            // setCurrentStep(2);
            setModal(true);


        } else if (role === ROLES.TRUCKING_COMPANY_ADMIN) {
            setCurrentStep(3);
            setSelectedRole(role);
            userUpdate({
                roles: [role] // Pass the selected role to update user's roles
            });
        } else if (role === ROLES.CARGO_OWNER) {
            // setCurrentStep(4);
            setModal(true);

        }
    };

    return (
        <div className='page-additional'>
            <h1 className="page-additional__title">Qo'shimcha ma'lumotlar</h1>

            <ProgressBar currentStep={currentStep} style={{ marginTop: 24 }} />

            <form className='form'>
                <h4 className="form__title">
                    Rolingizni tanlang
                </h4>

                <FlexWrapper style={{ marginTop: 16 }}>
                    {RoleCards.map(({ iconUrl, title, role }) => (
                        <RoleCard
                            key={role}
                            iconUrl={iconUrl}
                            title={title}
                            isActive={selectedRole === role}
                            onClick={() => handleRoleClick(role)}
                        />
                    ))}
                </FlexWrapper>

                <div className="tabs-wrapper">
                    {selectedRole === ROLES.LOGISTICIAN && (
                        <LogisticianTab />
                    )}
                    {selectedRole === ROLES.TRUCKING_COMPANY_ADMIN && (
                        <TruckDeliveryTab />
                    )}
                    {selectedRole === ROLES.CARGO_OWNER && (
                        <CargoOwnerTab selectedAmount={selectedAmount} setSelectedAmount={setSelectedAmount} />
                    )}
                </div>
            </form>
            {modal && (
                <Modal titlePosition={'center'} title={t("info")} onClose={()=>setModal(false)}>
                    <p style={{textAlign : "center"}}>{t("development_stage")}</p>
                </Modal>
            )}

        </div>
    )
}

export default Addtional