import { YMaps, Map } from '@pbe/react-yandex-maps';
import { PropsWithChildren } from 'react';

const YandexMap: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <YMaps query={{ apikey: '14582ab3-699a-4ff3-b2bb-80850b4fc412', lang: 'ru_RU' }} >
      <Map
        defaultState={{
          center: [41.311365, 69.279593],
          zoom: 13,
          controls: ['zoomControl', 'fullscreenControl']
        }}
        width="100%"
        height="100%"
        modules={['control.ZoomControl', 'control.FullscreenControl']}
      >
        {children}
      </Map>
    </YMaps>
  );
};

export default YandexMap;
