import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../index";
import { CreateCargoData } from "./types";
import { toast } from "react-toastify";

const createCargo = async (data: CreateCargoData): Promise<any> => {
    const response = await axiosInstance.post("/loads", data);
    return response.data;
};

const useCreateCargo = () => {
    return useMutation<any, Error, CreateCargoData>({
        mutationFn: createCargo,
        onSuccess: () => {
            toast.success("Yuk muvaffaqiyatli yaratildi");
        },
        onError: (error) => {
            toast.error(error.message || "Yukni yaratishda xatolik yuz berdi");
        }
    });
};

export default useCreateCargo;
