import { ReactNode, useState } from 'react';
import { UploadIcon } from "../../../../../utils/icons";
import Accardion from '../../../../../components/common/Accardion';
import ContentWrapper from '../../../../../components/common/ContentWrapper';
import FlexWrapper from '../../../../../components/common/FlexWrapper';
import PageInfo from '../../../../../components/dashboard/PageInfo';
import Button from '../../../../../components/common/Button';
import DragNDrop from '../../../../../components/common/DragNDrop';
import { Modal } from '../../../../../components/common/Modal';
import Combobox from '../../../../../components/common/Combobox';
import useModal from '../../../../../hooks/useModal';
import { createDocSelect } from '../../../../../utils/consts';
import { useTranslation } from 'react-i18next'; 
import { useCompanyDocuments } from '../../../../../api/hooks/company/useCompanyDocuments';
import { useParams } from 'react-router-dom';
import { CompanyDocument } from '../../../../../api/types/company/CompanyDocument'; // Add CompanyDocument type import
import './styles.sass';

const CompanyDocs = ({ navigationSlot }: { navigationSlot: ReactNode }) => {
    const createDocModal = useModal();
    const removeDocModal = useModal();
    const { t } = useTranslation();
    const  {companyID} = useParams()


    
    // State for selected document type
    const [selectedDocType, setSelectedDocType] = useState<string | number | null>(null);


    const {data} = useCompanyDocuments(companyID)

    console.log(data)

    return (
        <div className='page-companies-view'>
            <PageInfo
                title='OOO UMID AVTO LOGISTICS'
                backButton
                url='/dashboard/companies'
                buttonsSlot={
                    <Button
                        variant='secondary'
                        onClick={createDocModal.openModal}
                        iconLeft={<UploadIcon width={16} height={16} />}
                    >
                        {t('new_document_upload')}
                    </Button>
                }
            />

            <FlexWrapper>
                {navigationSlot}

                <div className="page-content">
                    <ContentWrapper className='company-docs'>
                        <h3 className="section-title">{t('company_docs')}</h3>

                        <Accardion 
                            documents={data || []} 
                            onRemoveModal={removeDocModal}
                            onDocumentClick={(doc: CompanyDocument) => {
                                if (doc.files && doc.files.length > 0) {
                                    window.open(doc.files[0].path, '_blank');
                                }
                            }} 
                        />
                    </ContentWrapper>
                </div>
            </FlexWrapper>

            {createDocModal.isOpen && (
                <Modal onClose={createDocModal.closeModal} title={t('new_document_upload')}>
                    <p className="create-doc__label">{t('new_file_name')}</p>
                    <Combobox
                        placeholder={t('driver_license')}
                        options={createDocSelect}
                        onSelect={(value) => {
                            setSelectedDocType(value);
                        }}
                    />

                    <p className="create-doc__label">{t('document')}</p>
                    <DragNDrop
                        containerSize="sm"
                        label="Guvohnomasi fayli"
                        fileTypeDescr="Format .pdf, .jpg or .png yuklang"
                        documentData={{
                            documentType: selectedDocType, // Передаем выбранный тип документа
                            entityType: "COMPANY",
                            name: "Company Certificate",
                        }}
                        onFileUpload={(fileData) => {
                            console.log('Uploaded file data:', fileData);
                            // Дополнительная логика обработки файла
                        }}
                    />
                    <div className="modal__btns">
                        <Button
                            variant='secondary'
                            fullWidth
                            onClick={createDocModal.closeModal}
                        >
                            {t('cancel')}
                        </Button>

                        <Button fullWidth>
                            {t('save')}
                        </Button>
                    </div>
                </Modal>
            )}

            {removeDocModal.isOpen && (
                <Modal
                    onClose={removeDocModal.closeModal}
                    titlePosition='center'
                    title={t('confirm_delete')}
                >
                    <p className='remoev-doc__desc'>
                        {t('delete_confirmation')}
                    </p>

                    <div className="modal__btns">
                        <Button
                            variant='secondary'
                            fullWidth
                            onClick={removeDocModal.closeModal}
                        >
                            {t('no_delete')}
                        </Button>
                        <Button fullWidth>{t('yes_delete')}</Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default CompanyDocs;