import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../..';
import { CompanyDocument } from '../../types/company/CompanyDocument';

export const useCompanyDocuments = (companyId: string | undefined) => {
  return useQuery({
    queryKey: ['company-documents', companyId],
    queryFn: async (): Promise<CompanyDocument[]> => {
      if (!companyId) return [];
      const { data } = await axiosInstance.get(`/documents/${companyId}`);
      return data;
    },
    enabled: !!companyId,
  });
};
