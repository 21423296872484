import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import PageInfo from "../../../components/dashboard/PageInfo";
import Button from "../../../components/common/Button";
import Input from '../../../components/common/Input';
import DragNDrop from '../../../components/common/DragNDrop';
import useCreateCargo from '../../../api/hooks/cargo/useCreateCargo';
import useMe from '../../../api/hooks/useMe';
import styles from './styles.module.scss';
import {getCookie} from "../../../utils/cookie";

const DEFAULT_LOAD_TYPE_ID = "a83853ea-f070-4191-a0e1-36a9e48fb08e"; // Используем дефолтный ID если не указан другой

const CreateLoad = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const token = getCookie('accessToken');
    const decodedToken: any = token ? jwtDecode(token) : null;
    const { data: userData } = useMe(decodedToken?.id);
    const createCargoMutation = useCreateCargo();

    const [formData, setFormData] = useState({
        companyInn: '',
        // yukId: '',
        transportType: '',
        pickupCompany: '',
        pickupAddress: '',
        pickupCity: '',
        pickupZip: '',
        pickupDate: '',
        pickupNote: '',
        pickupEmail: '',
        pickupPhone: '',
        deliveryCompany: '',
        deliveryAddress: '',
        deliveryCity: '',
        deliveryZip: '',
        deliveryDate: '',
        deliveryNote: '',
        deliveryEmail: '',
        deliveryPhone: '',
        cargoName: '',
        cargoType: '',
        cargoWeight: '',
        cargoUnit: 'Kg',
        cargoVolume: '',
        cargoLength: '',
        cargoWidth: '',
        cargoHeight: '',
        cargoNote: '',
        paymentType: '',
        cargoPrice: '',
        paymentAmount: '',
        paymentNote: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        invoiceId: '',
        invoiceNote: ''
    });

    const validateForm = () => {
        if (!formData.cargoName?.trim()) {
            toast.error(t('cargo.create.validation.cargoNameRequired'));
            return false;
        }
        if (!formData.pickupCompany?.trim()) {
            toast.error(t('cargo.create.validation.pickupCompanyRequired'));
            return false;
        }
        if (!formData.deliveryCompany?.trim()) {
            toast.error(t('cargo.create.validation.deliveryCompanyRequired'));
            return false;
        }
        if (!formData.pickupDate) {
            toast.error(t('cargo.create.validation.pickupDateRequired'));
            return false;
        }
        if (!formData.deliveryDate) {
            toast.error(t('cargo.create.validation.deliveryDateRequired'));
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!userData?.ownedCompanies?.[0]?.id || !validateForm()) {
            return;
        }

        const cargoData = {
            companyId: userData.ownedCompanies[0].id,
            transportType: "TRUCK" as const,
            pickupDetails: {
                businessName: formData.pickupCompany,
                address: formData.pickupAddress,
                city: formData.pickupCity,
                zipCode: formData.pickupZip,
                date: new Date(formData.pickupDate).toISOString(),
                note: formData.pickupNote || "",
                companyName: formData.pickupCompany,
                phone: formData.pickupPhone || "",
                email: formData.pickupEmail || ""
            },
            deliveryDetails: {
                businessName: formData.deliveryCompany,
                address: formData.deliveryAddress,
                city: formData.deliveryCity,
                zipCode: formData.deliveryZip,
                date: new Date(formData.deliveryDate).toISOString(),
                note: formData.deliveryNote || "",
                companyName: formData.deliveryCompany,
                phone: formData.deliveryPhone || "",
                email: formData.deliveryEmail || ""
            },
            loadName: formData.cargoName.trim(),
            loadTypeId: formData.cargoType?.trim() || DEFAULT_LOAD_TYPE_ID,
            weight: Math.max(0, Number(formData.cargoWeight) || 1),
            weightMeasure: "KG" as const,
            amount: Math.max(0, Number(formData.cargoVolume) || 1),
            amountMeasure: "KG" as const,
            height: Math.max(0, Number(formData.cargoHeight) || 1),
            heightMeasure: "M" as const,
            width: Math.max(0, Number(formData.cargoWidth) || 1),
            widthMeasure: "M" as const,
            length: Math.max(0, Number(formData.cargoLength) || 1),
            lengthMeasure: "M" as const,
            specificationNote: formData.cargoNote || "",
            shipper: {
                name: formData.contactName || formData.pickupCompany,
                address: formData.pickupAddress,
                city: formData.pickupCity,
                zip: formData.pickupZip,
                fullName: formData.contactName || formData.pickupCompany,
                phone: formData.contactPhone || formData.pickupPhone || "",
                email: formData.contactEmail || formData.pickupEmail || ""
            }
        };

        try {
            await createCargoMutation.mutateAsync(cargoData);
            navigate('/cargo');
        } catch (error: any) {
            console.error('Error creating cargo:', error);
            toast.error(error.response?.data?.message || 'Error creating cargo');
        }
    };

    return (
        <div className="page-compaines-create">
            <PageInfo 
                title={t('cargo.create.title')}
                backButton
                buttonsSlot={
                    <Button onClick={handleSubmit} disabled={createCargoMutation.isPending}>
                        {t('cargo.create.save')}
                    </Button>
                }
            />
            
            <div className={styles.createForm}>
                <h1>{t('cargo.create.cargo_details')}</h1>
                
                <div className={styles.mainInfo}>
                    <div className={styles.field}>
                        <label>{t('cargo.create.company_inn')}</label>
                        <Input
                            value={formData.companyInn}
                            onChange={(e) => setFormData({ ...formData, companyInn: e.target.value })}
                            placeholder="123 453 534"
                        />
                    </div>
                    {/*<div className={styles.field}>*/}
                    {/*    <label>{t('cargo.create.cargo_id')}</label>*/}
                    {/*    <Input*/}
                    {/*        value={formData.yukId}*/}
                    {/*        onChange={(e) => setFormData({ ...formData, yukId: e.target.value })}*/}
                    {/*        placeholder="123243235"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className={styles.field}>
                        <label>{t('cargo.create.transport_mode')}</label>
                        <Input
                            value={formData.transportType}
                            onChange={(e) => setFormData({ ...formData, transportType: e.target.value })}
                        />
                    </div>
                </div>

                <div className={styles.twoColumns}>
                    <div className={styles.column}>
                        <h2>{t('cargo.create.pickup.title')}</h2>
                        <div className={styles.field}>
                            <label>{t('cargo.create.pickup.business_name')}</label>
                            <Input
                                value={formData.pickupCompany}
                                onChange={(e) => setFormData({ ...formData, pickupCompany: e.target.value })}
                                placeholder="AIRCUZ Logistics"
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.pickup.address')}</label>
                            <Input
                                value={formData.pickupAddress}
                                onChange={(e) => setFormData({ ...formData, pickupAddress: e.target.value })}
                            />
                        </div>
                        <div className={styles.twoFields}>
                            <div className={styles.field}>
                                <label>{t('cargo.create.pickup.city')}</label>
                                <Input
                                    value={formData.pickupCity}
                                    onChange={(e) => setFormData({ ...formData, pickupCity: e.target.value })}
                                />
                            </div>
                            <div className={styles.field}>
                                <label>{t('cargo.create.pickup.zip')}</label>
                                <Input
                                    value={formData.pickupZip}
                                    onChange={(e) => setFormData({ ...formData, pickupZip: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.pickup.pickup_date')}</label>
                            <Input
                                type="date"
                                value={formData.pickupDate}
                                onChange={(e) => setFormData({ ...formData, pickupDate: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.pickup.note')}</label>
                            <Input
                                value={formData.pickupNote}
                                onChange={(e) => setFormData({ ...formData, pickupNote: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.pickup.email')}</label>
                            <Input
                                value={formData.pickupEmail}
                                onChange={(e) => setFormData({ ...formData, pickupEmail: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.pickup.phone')}</label>
                            <Input
                                value={formData.pickupPhone}
                                onChange={(e) => setFormData({ ...formData, pickupPhone: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className={styles.column}>
                        <h2>{t('cargo.create.delivery.title')}</h2>
                        <div className={styles.field}>
                            <label>{t('cargo.create.delivery.business_name')}</label>
                            <Input
                                value={formData.deliveryCompany}
                                onChange={(e) => setFormData({ ...formData, deliveryCompany: e.target.value })}
                                placeholder="AIRCUZ Logistics"
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.delivery.address')}</label>
                            <Input
                                value={formData.deliveryAddress}
                                onChange={(e) => setFormData({ ...formData, deliveryAddress: e.target.value })}
                            />
                        </div>
                        <div className={styles.twoFields}>
                            <div className={styles.field}>
                                <label>{t('cargo.create.delivery.city')}</label>
                                <Input
                                    value={formData.deliveryCity}
                                    onChange={(e) => setFormData({ ...formData, deliveryCity: e.target.value })}
                                />
                            </div>
                            <div className={styles.field}>
                                <label>{t('cargo.create.delivery.zip')}</label>
                                <Input
                                    value={formData.deliveryZip}
                                    onChange={(e) => setFormData({ ...formData, deliveryZip: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.delivery.delivery_date')}</label>
                            <Input
                                type="date"
                                value={formData.deliveryDate}
                                onChange={(e) => setFormData({ ...formData, deliveryDate: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.delivery.note')}</label>
                            <Input
                                value={formData.deliveryNote}
                                onChange={(e) => setFormData({ ...formData, deliveryNote: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.delivery.email')}</label>
                            <Input
                                value={formData.deliveryEmail}
                                onChange={(e) => setFormData({ ...formData, deliveryEmail: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.delivery.phone')}</label>
                            <Input
                                value={formData.deliveryPhone}
                                onChange={(e) => setFormData({ ...formData, deliveryPhone: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <h2>{t('cargo.create.cargo_specs.title')}</h2>
                    <div className={styles.cargoDetails}>
                        <div className={styles.field}>
                            <label>{t('cargo.create.cargo_specs.cargo_name')}</label>
                            <Input
                                value={formData.cargoName}
                                onChange={(e) => setFormData({ ...formData, cargoName: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.cargo_specs.cargo_type')}</label>
                            <Input
                                value={formData.cargoType}
                                onChange={(e) => setFormData({ ...formData, cargoType: e.target.value })}
                            />
                        </div>
                        <div className={styles.measurementFields}>
                            <div className={styles.field}>
                                <label>{t('cargo.create.cargo_specs.weight')}</label>
                                <div className={styles.withUnit}>
                                    <Input
                                        value={formData.cargoWeight}
                                        onChange={(e) => setFormData({ ...formData, cargoWeight: e.target.value })}
                                    />
                                    <span>{t('cargo.create.cargo_specs.units.kg')}</span>
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label>{t('cargo.create.cargo_specs.quantity')}</label>
                                <div className={styles.withUnit}>
                                    <Input
                                        value={formData.cargoVolume}
                                        onChange={(e) => setFormData({ ...formData, cargoVolume: e.target.value })}
                                    />
                                    <span>{t('cargo.create.cargo_specs.units.piece')}</span>
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label>{t('cargo.create.cargo_specs.height')}</label>
                                <div className={styles.withUnit}>
                                    <Input
                                        value={formData.cargoHeight}
                                        onChange={(e) => setFormData({ ...formData, cargoHeight: e.target.value })}
                                    />
                                    <span>{t('cargo.create.cargo_specs.units.meter')}</span>
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label>{t('cargo.create.cargo_specs.width')}</label>
                                <div className={styles.withUnit}>
                                    <Input
                                        value={formData.cargoWidth}
                                        onChange={(e) => setFormData({ ...formData, cargoWidth: e.target.value })}
                                    />
                                    <span>{t('cargo.create.cargo_specs.units.meter')}</span>
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label>{t('cargo.create.cargo_specs.length')}</label>
                                <div className={styles.withUnit}>
                                    <Input
                                        value={formData.cargoLength}
                                        onChange={(e) => setFormData({ ...formData, cargoLength: e.target.value })}
                                    />
                                    <span>{t('cargo.create.cargo_specs.units.meter')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.cargo_specs.note')}</label>
                            <Input
                                value={formData.cargoNote}
                                onChange={(e) => setFormData({ ...formData, cargoNote: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <h2>{t('cargo.create.payment.title')}</h2>
                    <div className={styles.twoFields}>
                        <div className={styles.field}>
                            <label>{t('cargo.create.payment.cargo_price')}</label>
                            <Input
                                value={formData.cargoPrice}
                                onChange={(e) => setFormData({ ...formData, cargoPrice: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.payment.driver_payment')}</label>
                            <Input
                                value={formData.paymentAmount}
                                onChange={(e) => setFormData({ ...formData, paymentAmount: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <label>{t('cargo.create.payment.payment_type')}</label>
                        <Input
                            value={formData.paymentType}
                            onChange={(e) => setFormData({ ...formData, paymentType: e.target.value })}
                            placeholder="Bank orqali"
                        />
                    </div>
                    <div className={styles.field}>
                        <label>{t('cargo.create.payment.note')}</label>
                        <Input
                            value={formData.paymentNote}
                            onChange={(e) => setFormData({ ...formData, paymentNote: e.target.value })}
                        />
                    </div>
                </div>

                {/*<div className={styles.section}>*/}
                {/*    <h2>{t('cargo.create.documents.title')}</h2>*/}
                {/*    <div className={styles.documents}>*/}
                {/*        <DragNDrop*/}
                {/*            label={t('cargo.create.documents.waybill')}*/}
                {/*            documentData={{*/}
                {/*                documentType: "cargo_waybill",*/}
                {/*                entityType: "cargo",*/}
                {/*                name: "cargo_waybill"*/}
                {/*            }}*/}
                {/*            onFileUpload={(fileData) => console.log('Waybill uploaded:', fileData)}*/}
                {/*        />*/}
                {/*        <DragNDrop*/}
                {/*            label={t('cargo.create.documents.delivery_note')}*/}
                {/*            documentData={{*/}
                {/*                documentType: "cargo_delivery_note",*/}
                {/*                entityType: "cargo",*/}
                {/*                name: "cargo_delivery_note"*/}
                {/*            }}*/}
                {/*            onFileUpload={(fileData) => console.log('Delivery note uploaded:', fileData)}*/}
                {/*        />*/}
                {/*        <DragNDrop*/}
                {/*            label={t('cargo.create.documents.invoice')}*/}
                {/*            documentData={{*/}
                {/*                documentType: "cargo_invoice",*/}
                {/*                entityType: "cargo",*/}
                {/*                name: "cargo_invoice"*/}
                {/*            }}*/}
                {/*            onFileUpload={(fileData) => console.log('Invoice uploaded:', fileData)}*/}
                {/*        />*/}
                {/*        <button className={styles.addDocument}>*/}
                {/*            + {t('cargo.create.documents.add_file')}*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={styles.section}>
                    <h2>{t('cargo.create.contact.title')}</h2>
                    <div className={styles.field}>
                        <label>{t('cargo.create.contact.full_name')}</label>
                        <Input
                            value={formData.contactName}
                            onChange={(e) => setFormData({ ...formData, contactName: e.target.value })}
                            placeholder="AIRCUZ Logistics"
                        />
                    </div>
                    <div className={styles.twoFields}>
                        <div className={styles.field}>
                            <label>{t('cargo.create.contact.phone')}</label>
                            <Input
                                value={formData.contactPhone}
                                onChange={(e) => setFormData({ ...formData, contactPhone: e.target.value })}
                            />
                        </div>
                        <div className={styles.field}>
                            <label>{t('cargo.create.contact.email')}</label>
                            <Input
                                value={formData.contactEmail}
                                onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <h2>{t('cargo.create.invoice.title')}</h2>
                    <div className={styles.field}>
                        <label>{t('cargo.create.invoice.invoice_id')}</label>
                        <Input
                            value={formData.invoiceId}
                            onChange={(e) => setFormData({ ...formData, invoiceId: e.target.value })}
                        />
                    </div>
                    <div className={styles.field}>
                        <label>{t('cargo.create.invoice.note')}</label>
                        <Input
                            value={formData.invoiceNote}
                            onChange={(e) => setFormData({ ...formData, invoiceNote: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateLoad;