import React, { ChangeEvent, CSSProperties, FC, useState } from "react";
import { UploadIcon, CloseIcon } from "../../../utils/icons"; // Добавить иконку крестика
import classNames from "classnames";
import "./styles.sass";
import { useUploadFile } from "../../../api/hooks/fileupload/uoploadFile";
import { toast } from "react-toastify";

interface DragNDropProps {
    accept?: string;
    label?: string;
    containerSize?: "sm" | "md";
    fileTypeDescr?: string;
    style?: CSSProperties;
    documentData: {
        documentType?: any;
        entityType?: string;
        companyId? :string
        entityId?: any;
        userId?: string;
        side? : string;
        name: string;
    };
    onFileUpload?: (fileData: any) => void; // Callback для обработки ответа после загрузки
    disabled?: boolean; // Новый пропс для отключения
}
const DragNDrop: FC<DragNDropProps> = ({
                                           label,
                                           accept = ".pdf,.jpg,.jpeg,.png",
                                           containerSize = "md",
                                           fileTypeDescr = "Format .pdf, .jpg, .jpeg or .png",
                                           style,
                                           documentData,
                                           onFileUpload,
                                           disabled = false,
                                       }) => {
    const { mutate: upload } = useUploadFile();
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

    // Генерация уникального id
    const inputId = `file-upload-input-${documentData.name}`;

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", file);
            Object.entries(documentData).forEach(([key, value]) =>
                formData.append(key, value)
            );

            upload(formData, {
                onSuccess: (response) => {
                    toast.success("Файл успешно загружен!");
                    setUploadedFileName(file.name); // Установить имя загруженного файла
                    if (onFileUpload) {
                        onFileUpload(response);
                    }
                },
                onError: (error) => {
                    toast.error("Ошибка загрузки файла");
                    console.error("File upload error:", error);
                },
                onSettled: () => {
                    setIsUploading(false); // Сброс состояния загрузки
                },
            });
        }
    };

    const handleRemoveFile = () => {
        setUploadedFileName(null); // Сбросить состояние файла
        toast.info("Файл удалён");
    };

    return (
        <div
            className={classNames(
                "drag-n-drop",
                `drag-n-drop--${containerSize}`,
                { "drag-n-drop--disabled": disabled }
            )}
            style={{ ...style }}
        >
            {label && <p className="drag-n-drop__label">{label}</p>}
            <div className="drag-n-drop__wrapper">
                {!uploadedFileName ? (
                    <label
                        htmlFor={inputId}
                        className={classNames("drag-n-drop__upload-area", {
                            "upload-area--disabled": disabled,
                        })}
                    >
                        <div className="upload-icon">
                            <UploadIcon />
                        </div>
                        <div className="file-type-descr">
                            {fileTypeDescr} yuklang <br />
                            <span>yoki ko'chirib olib keling</span>
                        </div>
                    </label>
                ) : (
                    <div className="drag-n-drop__file-info">
                        <span className="file-name">{uploadedFileName}</span>
                        <button
                            type="button"
                            className="remove-file-button"
                            onClick={handleRemoveFile}
                            disabled={disabled}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                )}
                <input
                    type="file"
                    name="file"
                    id={inputId} // Уникальный идентификатор
                    accept={accept}
                    onChange={handleFileChange}
                    disabled={disabled || isUploading || !!uploadedFileName}
                />
            </div>
        </div>
    );
};

export default DragNDrop;