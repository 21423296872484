import { Placemark } from "@pbe/react-yandex-maps";
import YandexMap from "../../../components/dashboard/Map";
import { ReactNode, useEffect, useState } from "react";
import FlexWrapper from "../../../components/common/FlexWrapper";
import PageInfo from "../../../components/dashboard/PageInfo";
import { CompanyLocations, locationsApi } from "../../../api/locations";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { getUserIdFromToken } from "../../../utils/authUtils";
import useCompanies from "../../../api/hooks/company/useCompanies";
import cargoIcon from "../../../assets/global/map-icons/cargo.svg";

interface Props {
  navigationSlot: ReactNode;
}

const MapCargos = ({ navigationSlot }: Props) => {
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [locations, setLocations] = useState<CompanyLocations | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const userId = getUserIdFromToken();
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies(userId);

  // Автоматически выбираем компанию если она одна
  useEffect(() => {
    if (companies?.length === 1 && !selectedCompany) {
      setSelectedCompany(companies[0].id);
    }
  }, [companies, selectedCompany]);

  useEffect(() => {
    if (!selectedCompany) {
      setLocations(null);
      setError(null);
      return;
    }

    setLoading(true);
    setError(null);

    locationsApi
      .getCompanyLocations(selectedCompany)
      .then((response: AxiosResponse<CompanyLocations>) => {
        console.log("Received locations data:", response.data);
        setLocations(response.data);
      })
      .catch((error: any) => {
        console.error("Failed to fetch locations:", error);
        setError(t("Failed to fetch locations"));
        setLocations(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCompany, t]);

  const handleCompanyChange = (value: string) => {
    setSelectedCompany(value || null);
  };

  const renderContent = () => {
    if (isLoadingCompanies) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("Loading companies...")}
        </div>
      );
    }

    if (!companies?.length) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("No companies available")}
        </div>
      );
    }

    if (!selectedCompany && companies.length > 1) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("Please select a company")}
        </div>
      );
    }

    if (loading) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("Loading locations...")}
        </div>
      );
    }

    if (error) {
      return (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
          }}
        >
          {error}
        </div>
      );
    }

    return (
      <div style={{ height: "calc(100vh - 250px)" }}>
        <YandexMap>
          {/* Load markers */}
          {locations?.trailers
            ?.filter((trailer) => trailer.type === 'load')
            ?.map((trailer) => {
              const lat = parseFloat(trailer.latitude as any);
              const lng = parseFloat(trailer.longitude as any);
              if (isNaN(lat) || isNaN(lng)) return null;

              // Форматируем дату последнего обновления
              const lastUpdate = new Date(trailer.updatedAt).toLocaleString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              });

              // Форматируем координаты с округлением до 6 знаков
              const formattedLat = Number(lat).toFixed(6);
              const formattedLng = Number(lng).toFixed(6);

              return (
                <Placemark
                  key={trailer.id}
                  geometry={[lat, lng]}
                  options={{
                    iconLayout: "default#image",
                    iconImageHref: cargoIcon,
                    iconImageSize: [60, 62],
                    iconImageOffset: [-30, -62],
                    hideIconOnBalloonOpen: false,
                    balloonCloseButton: true,
                  }}
                  properties={{
                    hintContent: trailer?.name || t("Load"),
                    balloonContentHeader: `<strong>${trailer?.name || t("Load")}</strong>`,
                    balloonContentBody: `
                      <div style="font-family: Arial, sans-serif;">
                        <div style="margin-bottom: 8px;">
                          <strong>${t("ID")}:</strong> ${trailer?.id}
                        </div>
                        <div style="margin-bottom: 8px;">
                          <strong>${t("Coordinates")}:</strong><br/>
                          ${t("Latitude")}: ${formattedLat}<br/>
                          ${t("Longitude")}: ${formattedLng}
                        </div>
                        <div>
                          <strong>${t("Last Update")}:</strong><br/>
                          ${lastUpdate}
                        </div>
                      </div>
                    `,
                  }}
                  modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                />
              );
            })}
        </YandexMap>
      </div>
    );
  };

  return (
    <div className="page-maps-view">
      <PageInfo backButton title={t("Xaritalar")} url="/dashboard" />
      <FlexWrapper>
        {navigationSlot}
        <div className="page-content">
          <div style={{ marginBottom: "20px" }}>
            {/* Показываем селект только если компаний больше одной */}
            {companies && companies.length > 1 && (
              <select
                style={{
                  width: "300px",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #d9d9d9",
                }}
                value={selectedCompany || ""}
                onChange={(e) => handleCompanyChange(e.target.value)}
                disabled={isLoadingCompanies}
              >
                <option value="">{t("Select a company")}</option>
                {Array.isArray(companies) &&
                  companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          {renderContent()}
        </div>
      </FlexWrapper>
    </div>
  );
};

export default MapCargos;