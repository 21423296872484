import axiosInstance from "../../index";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

interface Company {
    id: string;
    name: string;
    director?: string;
    tin?: string;
    driversCount?: number;
    transportsCount?: number;
    createdYear?: number;
}

// Функция для запроса данных компаний
const fetchCompanies = async (userId: string): Promise<Company[]> => {
    const response = await axiosInstance.get(`/user/${userId}`);
    console.log('Full API response:', response.data);
    console.log('Companies data:', response.data.ownedCompanies);
    return response.data.ownedCompanies;
};

// Хук для получения списка компаний
const useCompanies = (userId: string | null): UseQueryResult<Company[], Error> => {
    return useQuery<Company[], Error>({
        queryKey: ['companies', userId],
        queryFn: () => fetchCompanies(userId || ""),
        enabled: !!userId,
    });
};

export default useCompanies;