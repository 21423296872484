import { useNavigate, useParams } from "react-router-dom";
import PageInfo from "../../../components/dashboard/PageInfo";
import Button from "../../../components/common/Button";
import ContentWrapper from "../../../components/common/ContentWrapper";
import FlexWrapper from "../../../components/common/FlexWrapper";
import Input from "../../../components/common/Input";
import DragNDrop from "../../../components/common/DragNDrop";
import Combobox from "../../../components/common/Combobox";
import RadioInput from "../../../components/common/RadioInput";
import './styles.sass';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateTruck } from "../../../api/hooks/company/transports/useCreateTruck";
import { useEffect, useState } from "react";
import { useCompanyDrivers } from "../../../api/hooks/company/employe/useCompanyDrivers";

const TrucksCreate = () => {
  const navigate = useNavigate();
  const { companyID } = useParams();
  const { t } = useTranslation();
  const [yearOptions, setYearOptions] = useState<any[]>([]);
  const [carStatus, setCarStatus] = useState<string>("INACTIVE");
  const [carYear , setCarYear] = useState<any>([]);
  const [selectedDriver, setSelectedDriver] = useState<any>(null);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const { mutateAsync: registerTruck } = useCreateTruck();
  const { data: drivers = [] } = useCompanyDrivers(companyID || '');

  const driverOptions:any = drivers.map(driver => ({
    label: driver.user.name,
    value: driver.id
  }));
  console.log(driverOptions)
  useEffect(() => {
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year >= 1990; year--) {
        years.push({ label: year, value: year });
      }
      return years;
    };
    setYearOptions(generateYears());
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const truckData = {
        name: data.name,
        model: data.model,
        serial: data.serial,
        number: data.number,
        status: "AVAILABLE",
        companyId: companyID,
        trailer: data.trailer,
        driver: selectedDriver?.value,
        // driverAddress: data.driverAddress,
        note: data.note,
      };

      await registerTruck({
        ...truckData,
        year : carYear.toString(),
        type: "TRUCK",

      });

      navigate(-1);  // Go back to the previous page after success
    } catch (error: any) {
      toast.error(t(error?.message || 'Error'));
      console.error("Error creating truck:", error);
    }
  };

  return (
      <div className='page-trucks-create'>
        <PageInfo
            title={t('title')}
            backButton
            buttonsSlot={
              <Button onClick={handleSubmit(onSubmit)}>
                {t('saveButton')}
              </Button>
            }
        />

        <ContentWrapper rowGap={16}>
          <h3 className="section-title">{t('sectionTitleVehicleInfo')}</h3>

          <FlexWrapper>
            <Input
                label={t('vehicleNameLabel')}
                placeholder={t('vehicleNamePlaceholder')}
                {...register('name', { required: t('vehicleNameError') })}
                error={errors.name ? errors.name.message : ''}
            />
            <Input
                label={t('vehicleModelLabel')}
                placeholder={t('vehicleModelPlaceholder')}
                {...register('model', { required: t('vehicleModelError') })}
                error={errors.model ? errors.model.message : ''}
            />
            <Combobox
                label={t('yearOfManufactureLabel')}
                options={yearOptions}
                onSelect={(value: any) => {setCarYear(value)}}
                placeholder={t('yearOfManufacturePlaceholder')}
            />
          </FlexWrapper>

          <FlexWrapper>
            <Input
                label={t('vehiclePassportLabel')}
                placeholder={t('vehiclePassportPlaceholder')}
                {...register('serial', { required: t('vehiclePassportError') })}
                error={errors.serial ? errors.serial.message : ''}
            />
            <Input
                label={t('vehicleNumberLabel')}
                placeholder={t('vehicleNumberPlaceholder')}
                {...register('number', { required: t('vehicleNumberError') })}
                error={errors.number ? errors.number.message : ''}
            />
          </FlexWrapper>

          {/*<FlexWrapper style={{ marginBottom: 24 }}>*/}
          {/*  <DragNDrop containerSize='sm' label={t('frontPassportLabel')} />*/}
          {/*  <DragNDrop containerSize='sm' label={t('backPassportLabel')} />*/}
          {/*</FlexWrapper>*/}
        </ContentWrapper>

        <ContentWrapper style={{ marginTop: 16 }}>
          <h3 className="section-title">{t('sectionTitleCompliance')}</h3>

          <FlexWrapper label={t('activeStatusLabel')}>
            <RadioInput
                {...register('status', { required: true })}
                value="ACTIVE"
                label={t('activeLabel')}
                onChange={() => setValue('status', 'ACTIVE')}
                error={errors.status ? errors.status.message : ''}
            />
            <RadioInput
                {...register('status', { required: true })}
                value="INACTIVE"
                label={t('inactiveLabel')}
                onChange={() => setValue('status', 'INACTIVE')}
                error={errors.status ? errors.status.message : ''}
            />
          </FlexWrapper>
          <FlexWrapper>
            <Combobox label={t('trailerLabel')} placeholder={t('trailerPlaceholder')} />
            <Combobox 
              label={t('driverLabel')} 
              placeholder={t('driverPlaceholder')} 
              options={driverOptions}
              onSelect={(value) => setSelectedDriver(value)}
            />
            <Input label={t('driverAddressLabel')} placeholder={t('driverAddressPlaceholder')} {...register('driverAddress')} />
          </FlexWrapper>

          <FlexWrapper>
            <Input label={t('noteLabel')} placeholder={t('notePlaceholder')} className='wrapper-grid' />
          </FlexWrapper>
        </ContentWrapper>
      </div>
  );
};

export default TrucksCreate;