import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../index";
export const uploadFile = async (fileData: FormData) => {
    const response = await axiosInstance.post("/files/upload", fileData, {
        headers: {
            "Content-Type": "multipart/form-data", // Убедитесь, что заголовок для form-data установлен
        },
    });
    return response.data;
};



export const useUploadFile = () => {
    return useMutation({
        mutationFn : uploadFile,
        onSuccess: (data) => {
            console.log("Файл успешно загружен:", data);
        },
        onError: (error) => {
            console.error("Ошибка при загрузке файла:", error);
        },
    });
};