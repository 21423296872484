import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import Combobox from '../../../components/common/Combobox';
import ContentWrapper from '../../../components/common/ContentWrapper';
import styles from './styles.module.scss';
import classNames from 'classnames';
import PageInfo from "../../../components/dashboard/PageInfo";
import { PlusIcon } from "../../../utils/icons";
import { useNavigate } from "react-router-dom";
import useCargoList from '../../../api/hooks/cargo/useCargoList';
import { Load } from '../../../api/types/cargo.types';

type TabType = 'loadboard' | 'new' | 'in_progress' | 'on_way' | 'delivered' | 'completed';

const statusOptions = [
  { value: 'PENDING', label: 'Yangi' },
  { value: 'IN_PROGRESS', label: "Yo'naltirilgan" },
  { value: 'ON_WAY', label: "Yo'lda" },
  { value: 'DELIVERED', label: 'Yetkazib berilgan' },
  { value: 'COMPLETED', label: 'To\'langan' }
];

interface CargoCardProps {
  cargo: Load;
}

const CargoCard: React.FC<CargoCardProps> = ({ cargo }) => {
  const { t } = useTranslation();
  
  const getStatusLabel = (status: string) => {
    const statusOption = statusOptions.find(opt => opt.value === status);
    return statusOption ? statusOption.label : status;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU');
  };

  return (
    <div className={styles.cargoCard}>
      <div className={styles.cardHeader}>
        <div className={styles.cardId}>
          <div className={styles.checkbox} />
          <span className={styles.id}>{cargo.yukId}</span>
          <span className={styles.tag}>{getStatusLabel(cargo.status)}</span>
        </div>
        <div className={styles.headerRight}>
          <div className={styles.inn}>
            INN: <span>{cargo.company?.inn || '-'}</span>
          </div>
          <div className={styles.actions}>
            <button className={styles.primary}>Yo'lda deb belgilash</button>
            <button className={styles.secondary}>Edit</button>
          </div>
        </div>
      </div>

      <div className={styles.cardContent}>
        <div className={styles.section}>
          <h3>Yukni olish manzili</h3>
          <div className={styles.info}>
            <div className={styles.location}>{cargo.pickupAddress.address}</div>
            <div className={styles.region}>{cargo.pickupAddress.city}</div>
            <div className={styles.phone}>{cargo.pickupPhone}</div>
          </div>
        </div>

        <div className={styles.section}>
          <h3>Yukni yetkazish manzili</h3>
          <div className={styles.info}>
            <div className={styles.location}>{cargo.deliveryAddress.address}</div>
            <div className={styles.region}>{cargo.deliveryAddress.city}</div>
            <div className={styles.phone}>{cargo.deliveryPhone}</div>
          </div>
        </div>

        <div className={styles.section}>
          <h3>Mijoz</h3>
          <div className={styles.info}>
            <div className={styles.company}>{cargo.company?.name || cargo.pickupCompanyName}</div>
            <div className={styles.email}>{cargo.pickupEmail}</div>
            <div className={styles.address}>{cargo.pickupAddress.city}</div>
          </div>
        </div>

        <div className={styles.section}>
          <h3>Qo'shimcha ma'lumotlar</h3>
          <div className={styles.info}>
            <div className={styles.row}>
              <span className={styles.label}>Created by:</span>
              <span className={styles.value}>{cargo.shipperFullName}</span>
            </div>
            <div className={styles.row}>
              <span className={styles.label}>Created at:</span>
              <span className={styles.value}>{formatDate(cargo.createdAt)}</span>
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <h3>Eslatmalar</h3>
          <div className={styles.info}>
            <div className={styles.value}>{cargo.specificationNote || '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CargoList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabType>('loadboard');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const { data: cargoList, isLoading, error } = useCargoList();

  const filteredCargos = useMemo(() => {
    if (!cargoList) return [];

    return cargoList.filter(cargo => {
      const matchesSearch = searchQuery
        ? cargo.yukId.toLowerCase().includes(searchQuery.toLowerCase()) ||
          cargo.pickupAddress.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
          cargo.deliveryAddress.city.toLowerCase().includes(searchQuery.toLowerCase())
        : true;

      const matchesStatus = selectedStatus
        ? cargo.status === selectedStatus
        : true;

      const matchesTab = activeTab === 'loadboard' ? true : (() => {
        switch (activeTab) {
          case 'new':
            return cargo.status === 'PENDING';
          case 'in_progress':
            return cargo.status === 'IN_PROGRESS';
          case 'on_way':
            return cargo.status === 'ON_WAY';
          case 'delivered':
            return cargo.status === 'DELIVERED';
          case 'completed':
            return cargo.status === 'COMPLETED';
          default:
            return true;
        }
      })();

      return matchesSearch && matchesStatus && matchesTab;
    });
  }, [cargoList, searchQuery, selectedStatus, activeTab]);

  const handleCreateClick = () => {
    navigate('/cargo/create');
  };

  const handleStatusChange = (value: string | number) => {
    setSelectedStatus(value as string);
  };

  const tabCounts = useMemo(() => {
    if (!cargoList) return {
      loadboard: 0,
      new: 0,
      in_progress: 0,
      on_way: 0,
      delivered: 0,
      completed: 0
    };

    return {
      loadboard: cargoList.length,
      new: cargoList.filter(c => c.status === 'PENDING').length,
      in_progress: cargoList.filter(c => c.status === 'IN_PROGRESS').length,
      on_way: cargoList.filter(c => c.status === 'ON_WAY').length,
      delivered: cargoList.filter(c => c.status === 'DELIVERED').length,
      completed: cargoList.filter(c => c.status === 'COMPLETED').length
    };
  }, [cargoList]);

  return (
    <ContentWrapper>
      <PageInfo
        title={t('cargo.management')}
        buttonsSlot={
          <Button onClick={handleCreateClick} iconLeft={<PlusIcon width={20} height={20} stroke="#fff" />}>
            {t('cargo.create.title')}
          </Button>
        }
      />

      <div className={styles.filters}>
        <Input
          placeholder={t('cargo.search_by_id')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Combobox
          placeholder={t('cargo.status')}
          defaultValue={selectedStatus}
          onSelect={handleStatusChange}
          options={statusOptions}
          className={styles.filterSelect}
        />
      </div>

      <div className={styles.tabs}>
        <button
          className={classNames(styles.tab, { [styles.active]: activeTab === 'loadboard' })}
          onClick={() => {
            setActiveTab('loadboard');
            setSelectedStatus('');
          }}
        >
          Loadboard ({tabCounts.loadboard})
        </button>
        <button
          className={classNames(styles.tab, { [styles.active]: activeTab === 'new' })}
          onClick={() => {
            setActiveTab('new');
            setSelectedStatus('PENDING');
          }}
        >
          {t('cargo.new')} ({tabCounts.new})
        </button>
        <button
          className={classNames(styles.tab, { [styles.active]: activeTab === 'in_progress' })}
          onClick={() => {
            setActiveTab('in_progress');
            setSelectedStatus('IN_PROGRESS');
          }}
        >
          {t('cargo.in_progress')} ({tabCounts.in_progress})
        </button>
        <button
          className={classNames(styles.tab, { [styles.active]: activeTab === 'on_way' })}
          onClick={() => {
            setActiveTab('on_way');
            setSelectedStatus('ON_WAY');
          }}
        >
          {t('cargo.on_way')} ({tabCounts.on_way})
        </button>
        <button
          className={classNames(styles.tab, { [styles.active]: activeTab === 'delivered' })}
          onClick={() => {
            setActiveTab('delivered');
            setSelectedStatus('DELIVERED');
          }}
        >
          {t('cargo.delivered')} ({tabCounts.delivered})
        </button>
        <button
          className={classNames(styles.tab, { [styles.active]: activeTab === 'completed' })}
          onClick={() => {
            setActiveTab('completed');
            setSelectedStatus('COMPLETED');
          }}
        >
          {t('cargo.completed')} ({tabCounts.completed})
        </button>
      </div>

      {isLoading ? (
        <div className={styles.loading}>{t('loading')}</div>
      ) : error ? (
        <div className={styles.error}>{t('error')}</div>
      ) : (
        <div className={styles.cargoList}>
          {filteredCargos.map((cargo) => (
            <CargoCard key={cargo.id} cargo={cargo} />
          ))}
        </div>
      )}
    </ContentWrapper>
  );
};

export default CargoList;