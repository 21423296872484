import axiosInstance from "../../../index";
import { useQuery } from "@tanstack/react-query";

interface Driver {
    id: number | string;
    user: any;
    passportData: string;
    hgId: string;
    carNumber: string;
    cargoCount: number;
    status: string;

}

const fetchDrivers = async (companyId: string): Promise<Driver[]> => {
    const response = await axiosInstance.get(`/company-employee/company/${companyId}`);
    return response.data;
};

export const useCompanyDrivers = (companyId: string) => {
    return useQuery<Driver[], Error>({
        queryKey: ['company-drivers', companyId],
        queryFn: () => fetchDrivers(companyId),
        enabled: !!companyId,
    });
};